var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_vm.nationalContract ? _c('national-contract-level-table', {
    attrs: {
      "national-contract": _vm.nationalContract
    }
  }) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }