<template>
  <div class="card card-custom">
    <div class="card-body">
      <national-contract-level-table v-if="nationalContract"
        :national-contract="nationalContract"></national-contract-level-table>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import EmployeeNationalContractService from "@/core/services/employee/employee-nationalcontract.service";

import NationalContractLevelTable from "@/view/components/tables/NationalContractLevelTable.vue";
export default {
  components: {
    NationalContractLevelTable,
  },

  data() {
    return {
      nationalContract: null,
    };
  },

  computed: {
    nationalContractID() {
      return this.$route.params.ID;
    },

    nationalContractName() {
      return this.nationalContract?.name;
    },
  },

  async mounted() {
    try {
      this.nationalContract = await EmployeeNationalContractService.getOne(this.nationalContractID);
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("National contracts"), route: { name: "national-contract" } },
        { title: this.$t("Level") },
      ]);
    } catch (err) {
      console.log(err);
      this.$router.push({ name: "404" });
    }
  },
};
</script>
